<template>
  <div class="page-classroom-view">
    <div class="my-page" style="padding-bottom: 45px">
      <!-- 上方详情 -->
      <div class="my-card detail-box">
        <div class="card-main">
          <div class="left"></div>
          <div class="right">
            <div class="card-top">
              <div class="card-title ell">{{ viewData.FolderName }}</div>
            </div>
            <div class="card-center">
              <div class="name">{{ viewData.RealName }}</div>
              <span class="date">{{ viewData.CreateDate }}</span>
            </div>
            <div class="date">{{ viewData.CreateUser }}</div>
          </div>
        </div>
        <div class="card-bottom">
          <div class="content">{{ viewData.Desc }}</div>
          <div class="comment-d">
            <div class="left">
              <i class="i-eye"></i>
              <span class="nosee">{{ viewData.UpCount }}人已上传</span>
            </div>
            <div class="right" v-if="this.userType === '2'">
              <button
                style="
                  font-size: 13px;
                  background-color: #B7DAC5;
                  border-radius: 20px;
                  padding: 2px 5px;
                  color: #000;
                  border: none;
                "
                @click.stop="unupload"
              >
                查看结果
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- 评论区 -->
      <div class="my-card comment-box">
        <div class="comment-title">所有文件（{{ fileList.length }}）</div>
        <div class="comment-list">
          <template v-if="fileList && fileList.length > 0">
            <div
              v-for="(obj, index) in fileList"
              :key="index"
              class="comment-item"
            >
              <div class="user-box">
                <div class="user">
                  <img
                    :src="
                      obj.ChildrenHeadUrl ? obj.ChildrenHeadUrl : defaultImg
                    "
                  />
                  <span>{{ obj.ChildrenName }}</span>
                </div>
                <div class="date">{{ obj.CreateDate }}</div>
                <div class="button" v-if="userType == '3'">
                  <span class="xj-btn" @click.stop="delOpen(obj)">
                    <van-icon name="delete-o" />
                    <span class="xj">删除</span>
                  </span>
                </div>
              </div>
              <div class="files">
                <div
                  style="margin-bottom: 10px"
                  v-for="(item, index) in obj.FileDtoList"
                  :key="index"
                  @click="review(item)"
                >
                  {{ item.FileName }}
                </div>
                <!-- <van-image
                  v-for="(img, index) in obj.FileUrlList"
                  :key="index"
                  width="80"
                  height="74"
                  :src="img"
                  @click.stop="
                    () => {
                      images = [];
                      images.push(img);
                      imgShow = true;
                    }
                  "
                  style="margin-right: 10px;
                    margin-bottom: 10px;
                    border-radius: 6px;
                    overflow: hidden;
                    display: inline-block;
                  "
                /> -->
              </div>
            </div>
          </template>
          <van-empty v-else description="暂无文件" />
        </div>
      </div>
      <!-- 提交评论（家长） -->
      <div v-if="userType === '3'" class="submit-box" @click="addFile">
        <van-button type="primary" block color="#47AFA7">上传文件</van-button>
      </div>
    </div>
    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
    <!-- 驳回弹窗 -->
    <van-dialog
      v-model="dialogVisible"
      title="提示"
      :beforeClose="beforeClose"
      showCancelButton
    >
      <div style="text-align: center; margin-top: 20px; margin-bottom: 20px">
        确定要删除该文件吗？
      </div>
    </van-dialog>
  </div>
</template>

<script>
import defaultImg from "@/assets/icon/i-comment-defalut.png";
import filePic from "@/assets/icon/icon-file.png";
import { Image, ImagePreview, Empty, Button, Icon, Dialog } from "vant";
export default {
  components: {
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Empty.name]: Empty,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      defaultImg: defaultImg,
      filePic: filePic,
      id: null,
      viewData: {},
      fileList: [],
      comment: "",
      imgShow: false,
      images: [],
      idList: [],
      dialogVisible: false,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    // 详情
    this.getDetail();
    // 文件列表
    //this.getFileList()
  },
  methods: {
    getDetail() {
      let that = this;
      this.$axios
        .get("/api/ClassFolder/Get", {
          id: this.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.viewData = res.data;
            this.fileList = res.data.dtoList;
            if (this.fileList.length > 0) {
              this.$nextTick(() => {
                setTimeout(() => {
                  // 动态改变滚动高度
                  const dom = that.$jq(".comment-list");
                  dom.css(
                    "height",
                    that.$jq(window).height() -
                      dom.offset().top -
                      30 -
                      45 -
                      15 -
                      15 -
                      25
                  );
                }, 200);
              });
            }
          } else {
            that.$toast.fail(res.msg || "操作失败");
          }
        });
    },

    // 点赞
    goodFn() {
      this.$axios
        .post("/api/ClassCircle/ThumbUp", {
          ClassCircleID: this.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.msg);
            this.getDetail();
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    // 评论
    pushComment() {
      this.$axios
        .post("/api/ClassCircle/Comment", {
          ClassCircleID: this.id,
          Content: this.comment,
        })
        .then((res) => {
          if (res.code === 200) {
            this.comment = "";
            this.$toast.success("评论成功");
            // this.$router.go(0)
            window.location.reload();
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    // 未读列表
    unupload() {
      if (this.userType === "2") {
        this.$router.push({
          path: "FolderFileUpload",
          query: {
            id: this.id,
            noup: this.viewData.NoUP,
            up: this.viewData.UP,
          },
        });
      }
    },
    // 上传文件
    addFile() {
      this.$router.push({
        path: "ReportFileAdd",
        query: {
          id: this.id,
        },
      });
    },
    review(item) {
      if (item.FileUrl) {
        let lastName = item.FileUrl.slice(item.FileUrl.lastIndexOf(".") + 1);
        // if (
        //   lastName == "doc" ||
        //   lastName == "docx" || lastName == 'pdf' || lastName == 'xls' || lastName == 'xlsx' || lastName == 'ppt' || lastName == 'pptx' || lastName == 'jpg'
        // ) {

        // }
        var xurl = "http://view.xdocin.com/view?src=";
        //传入文档地址
        xurl += encodeURIComponent(item.FileUrl);
        //开始预览
        window.open(xurl);
      }
    },
    delOpen(item) {
      this.idList = [];
      item.FileDtoList.forEach(obj => {
        this.idList.push(obj.FileID);
      });
      this.dialogVisible = true;
    },
    beforeClose(action, done) {
      let that = this;
      if (action === "confirm") {
        this.$axios
          .post("/api/ClassFile/Delete", {
            IDList: this.idList,
          })
          .then((res) => {
            if (res.code === 200) {
              this.$toast.success(res.msg || "操作成功");

              done();
              setTimeout(() => {
                // that.$router.go(0)
                window.location.reload();
              }, 1000);
            } else {
              this.$toast.fail(res.msg || "操作失败");
            }
          });
      } else {
        done();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
